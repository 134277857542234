import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const WindroseWrapper = styled.div`
  display: none;
  @media (min-width: 768px) and (min-height: 600px) {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
`;

const WindroseCircle = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  width: 80%;
  height: 80%;
`;

const WindroseCenter = styled.p`
  position: absolute;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
`;

const WindroseDirection = styled.span`
  position: absolute;
  display: block;
  background: black;

  ${({ direction }) =>
    direction === 'n' &&
    css`
      top: 0;
      height: 20%;
      width: 2px;
      background: ${({ theme }) => theme.colors.primary};
    `}

  ${({ direction }) =>
    direction === 's' &&
    css`
      bottom: 0;
      height: 20%;
      width: 2px;
    `}

  ${({ direction }) =>
    direction === 'w' &&
    css`
      left: 0;
      width: 20%;
      height: 2px;
    `}

  ${({ direction }) =>
    direction === 'e' &&
    css`
      right: 0;
      width: 20%;
      height: 2px;
    `}
`;

const Windrose = ({ rotation, ...props }) => {
  return (
    <WindroseWrapper {...props} style={{ transform: `rotate(${rotation}deg)` }}>
      <WindroseCircle />
      <WindroseCenter style={{ transform: `rotate(${360 - rotation}deg)` }}>
        N
      </WindroseCenter>
      <WindroseDirection direction="n" />
      <WindroseDirection direction="s" />
      <WindroseDirection direction="w" />
      <WindroseDirection direction="e" />
    </WindroseWrapper>
  );
};

Windrose.propTypes = {
  rotation: PropTypes.number,
};

Windrose.defaultProps = {
  rotation: 0,
};

export default Windrose;
