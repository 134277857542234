import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta, title }) {
  const {
    wpPage: { seo },
  } = useStaticQuery(
    graphql`
      query querySEO {
        wpPage {
          seo {
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphSiteName
            opengraphImage {
              sourceUrl
            }
          }
        }
      }
    `
  );

  const ogImage = seo.opengraphImage
    ? { property: `og:image`, content: seo.opengraphImage.sourceUrl }
    : {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${seo.opengraphSiteName}`}
      script={
        [
          //         {
          //           type: 'text/javascript',
          //           innerHTML: `var _mtm = window._mtm = window._mtm || [];
          // _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
          // var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          // g.async=true; g.src='https://user.aitnetapp.com/js/container_RHayPmfR.js'; s.parentNode.insertBefore(g,s);`,
          //         },
          //         {
          //           type: 'text/javascript',
          //           innerHTML: `var _paq = window._paq = window._paq || [];_paq.push(['trackPageView']); _paq.push(['enableLinkTracking']); (function() { var u="https://user.aitnetapp.com/"; _paq.push(['setTrackerUrl', u+'matomo.php']); _paq.push(['setSiteId', '15']); var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s); })();`,
          //         },
        ]
      }
      meta={[
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: 'google-site-verification',
          content: '6xk06mw13JhdmitYVns3qoSGVfs_ZIBxTniI07fOFV0',
        },
        {
          name: `description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        {
          property: `og:title`,
          content: seo.opengraphTitle,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || seo.metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: seo.opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        ogImage,
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
